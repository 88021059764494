body{
	padding-top: 50px;
	font-weight: 300;
	@media (min-width: $screen-sm-min){
		padding-top: 100px;
	}
};
.champsInvalid{
 	border:1px solid red;
};

.inner-addon .fa {
  position: absolute;
  padding: 10px;
  pointer-events: none;
}
.modal-error .fa {
	position: absolute;
	pointer-events: none;
  }


.inner-addon {
  position: relative;
}
.right-addon .fa { right: 0px;}
.right-addon input { padding-right: 30px; }


.tabs {
    display:inline-block;
    float:left;
    height:30px;
    min-width:80px;
    line-height: 22px;
    padding:0 8px 0 8px;
    margin: 1px 0px 0px 0px;
    border-top: 1px solid gray;
    border-left: 1px solid gray;
    border-right: 1px solid gray;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;	
    font-size:14px;
    font-weight:bold;
    background:#F0F0F0;
}
.titre{
	margin:0.5px 0px;
};
.onglet{
	    list-style-type:none;
};.champsInvalid{
	border:1px solid red;
};

.inner-addon .fa {
	position: absolute;
	padding: 10px;
	pointer-events: none;
}


.inner-addon {
	position: relative;
}
.right-addon .fa { right: 0px;}
.right-addon input { padding-right: 30px; }


.tabs {
	display:inline-block;
	float:left;
	height:30px;
	min-width:80px;
	line-height: 22px;
	padding:0 8px 0 8px;
	margin: 1px 0px 0px 0px;
	border-top: 1px solid gray;
	border-left: 1px solid gray;
	border-right: 1px solid gray;
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	font-size:14px;
	font-weight:bold;
	background:#F0F0F0;
}
.titre{
	margin:0.5px 0px;
};
.onglet{
	list-style-type:none;
};

.clear {
	background:none;
	border:none;
	cursor:pointer;
	padding:5px 10px;
	position:absolute;
	right:15px;
	top:4px;
	color:#3d484f;
}

.caret{
	display: none;
}