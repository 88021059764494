.breadcrumb {
  display: inline-block;
  box-shadow: 1px 1px 3px #999;
  overflow: hidden;
  border-radius: 5px;
  counter-reset: flag;
  margin: 10px auto;
}

.breadcrumb a {
  text-decoration: none;
  outline: none;
  display: block;
  float: left;
  font-size: 12px;
  line-height: 36px;
  color: white;
  padding: 0 10px 0 60px;
  background: #666;
  background: linear-gradient(#666, #333);
  position: relative;
}

.breadcrumb a:first-child {
  padding-left: 46px;
  border-radius: 5px 0 0 5px;
}
.breadcrumb a:first-child:before {
  left: 14px;
}
.breadcrumb a:last-child {
  border-radius: 0 5px 5px 0;
  padding-right: 20px;
}

.breadcrumb a:after {
  content: '';
  position: absolute;
  top: 0;
  right: -18px;
  width: 36px;
  height: 36px;
  transform: scale(0.707) rotate(45deg);
  z-index: 1;
  background: #666;
  background: linear-gradient(135deg, #666, #333);
  box-shadow:
          2px -2px 0 2px rgba(0, 0, 0, 0.4),
          3px -3px 0 2px rgba(255, 255, 255, 0.1);
  border-radius: 0 5px 0 50px;
}

.breadcrumb a:last-child:after {
  content: none;
}

.breadcrumb a:before {
  content: counter(flag);
  counter-increment: flag;
  /*some styles now*/
  border-radius: 100%;
  width: 20px;
  height: 20px;
  line-height: 20px;
  margin: 8px 0;
  padding-left: 6px;
  position: absolute;
  top: 0;
  left: 30px;
  background: #444;
  background: linear-gradient(#444, #222);
  font-weight: bold;
}


.flat a, .flat a:after {
  background: white;
  color: black;
  transition: all 0.5s;
}
.flat a:before {
  background: white;
  color: black;
  box-shadow: 0 0 0 1px #ccc;
}

.flat a.active, .flat a.active:after{
  background: #44a7e0;
  color: white;
}

.flat a.disabled, .flat a.disabled:after {
  background: #c0c0c0;
  color: white;
}

.flat a.disabled:before {
  color: #c0c0c0;
}

.selectLine {
  background-color: #44a7e0;
  color: white;
}

.selectZoneVentilation {
  background-color: #f5f5f5;
  color: black;
}

.modal-dialog.modal-xxl {
  width: 90%;
}