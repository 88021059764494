//
// Close icons
// --------------------------------------------------


.close {
  float: right;
  line-height: 1;
  color: $bleu;
  text-shadow: $close-text-shadow;
  transition: color 0.3s ease-out;
  &:hover,
  &:focus {
    color: $violet;
    text-decoration: none;
    cursor: pointer;
  }

  // [converter] extracted button& to button.close
}

// Additional properties for button version
// iOS requires the button element instead of an anchor tag.
// If you want the anchor version, it requires `href="#"`.
// See https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile
button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}
