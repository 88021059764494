.autocomplete{

  height:80%;
  display:flex;
  align-items: center;
  justify-content: center;

  &__button{
    height:80%;
    display:flex;
    align-items: center;
    justify-content: center;
    font-size : 14px;
  }
}
