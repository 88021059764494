.connexion{
	padding-top: 0;
	background-color: $bleuVert;
	margin-top: -50px;
	font-weight: 300;
	@media (min-width: $screen-sm-min){
		margin-top: -100px;
	}
	&__container{
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100vh;
	}
	&__content{
		display: flex;
		align-items: center;
		flex-direction: column;
		background-color: #fff;
		width: 80%;
		max-width: 500px;
		padding: 20px 40px 10px;
		box-shadow: 0px 0px 20px #000;
		@media (min-width: $screen-sm-min){
			padding: 40px 80px 20px;
		}
	}
	&__logo{
		display: block;
		width: 120px;
		height: 38px;
		@media (min-width: $screen-sm-min){
			width: 240px;
			height: 76px;
		}
	}
	&__form{
		width: 100%;
		margin-top: 50px;
	}
	&__button{
		margin-top: 40px;
	}
	&__link{
		color: $violet;
		font-weight: 600;
		&:hover{
			text-decoration: underline;
		}
	}
}