.main{
	max-width: 1600px;
	padding-left: 67px;
	padding-right: 10px;
	padding-bottom: 40px;
	transition: padding-left 0.3s ease-out;
	@media (min-width: $screen-sm-min){
		padding-left: 107px;
		&.is-fold-up{
			padding-left: 277px;
		}
	}
	@media (min-width: $screen-md-min){
		padding-left: 237px;
		&.is-fold-up{
			padding-left: 237px;
		}
	}
}

.block{
	margin-top: 20px;
	padding: 20px;
	background-color: #fff;
}

.text-big{
	color: $colorMenu;
	font-size: 18px;
	font-weight: 400;
	a{
		color: $bleu;
		font-weight: 700;
	}
}

.table-hover {
  > tbody > tr:hover {
   .text-big, .text-big a{
		color: #fff;
	}
  }
}

.table {
	.btn + .btn.action{
		margin-left: 0px;
	}
}

tr.disabled{
	color: #b5b5b5;
	cursor: not-allowed;
}

.mb0{
	margin-bottom: 0;
}

.input-grey{
	border-color: $colorMenu;
	&::-moz-placeholder {
		color: $colorMenu;
		font-weight: 600;
	}
	&:-ms-input-placeholder { color: $colorMenu;font-weight: 600; } // Internet Explorer 10+
	&::-webkit-input-placeholder  { color: $colorMenu;font-weight: 600; }
}

.input-group-addon.input-grey{
	color: $colorMenu;
}