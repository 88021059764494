.menu{
	display: flex;
	position: fixed;
	top: 50px;
	bottom: 0;
	left: 0;
	background-color: $bgMenu;
	color: $colorMenu;
	font-weight: 400;
	overflow: hidden;
	text-transform: uppercase;
	@media (min-width: $screen-sm-min){
		top: 100px;
	}
	&__list{
		padding-top: 14px;
	}
	&__item{
		display: block;
		margin-top: 5px;
	}
	&__link{
		display: flex;
		align-items: center;
		height: 40px;
		border-left: solid 9px $bgMenu;
		white-space: nowrap;
		text-decoration: none;
		transition: border-color 0.3s ease-out, color 0.3s ease-out;
		&--active{
			border-color: $bleu;
			color: $bleu;
		}
		&:hover, &:focus{
			color: $bleu;
		}
		i{
			margin-left: 8px;
			width: 40px;
		}
		span{
			display: inline-block;
			width: 0px;
			transition: width 0.3s ease-out;
			@media (min-width: $screen-md-min){
				width: 170px;
			}
		}
	}
	&__fold-up{
		position: relative;
		width: 40px;
		padding: 25px 10px 0;
		background-color: $grisClair;
		overflow: hidden;
		&::before{
			content: '';
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: $bgMenu;
			transform: translate3d(-100%, 0, 0);
			transition: transform 0.3s ease-out;
		}
		button{
			display: block;
			position: relative;
			width: 20px;
			height: 22px;
		    padding: 0;	
			border: 0;
			outline: 0;
			background: none;
			color: inherit;
		    font: inherit;
		    line-height: normal;
		    overflow: visible;
		    cursor: pointer;
		}
		i{
			position: absolute;
			top: 5px;
			left: 5px;
			transition: opacity 0.3s ease-out;
			&.close{
				opacity: 0;
			}
		}
	}
	&.is-open &__link{
		@media (min-width: $screen-sm-min){
			span{
				width: 170px;
			}
		}
	}
	&.is-open &__fold-up::before{
		transform: translate3d(0, 0, 0);
	}
	&.is-open &__fold-up .close{
		opacity: 1;
	}
	&.is-open &__fold-up .open{
		opacity: 0;
	}
}