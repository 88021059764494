
div.multiline-select .ui-select-bootstrap .ui-select-choices-row > span {
  white-space: normal;
}

div.multiline-select .ui-select-bootstrap .ui-select-match > span.btn {
  white-space: normal;
  height: auto;
  min-height: 45px;
}

div.multiline-select .input-group,
div.multiline-select .input-group-btn {
  display : flex;
}

div.multiline-select .btn-default {
  border-color: black;
  color : black;
}

div.multiline-select .btn {
  text-transform: none;
}

.ui-select-placeholder {
  font-weight: 500;
  font-size: 12px;
}

div.multiline-select-errors .btn-default{
  border-color: #a94442 !important;
  z-index: 5;
}

div.multiline-select-warning .btn-default{
  border-color: #8a6d3b !important;
  z-index: 5;
}