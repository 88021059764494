.mainheader{
	display: flex;
	align-items: center;
	position: fixed;
	top: 0;
	left:0;
	right: 0;
	height: 50px;
	padding-left: 10px;
	padding-right: 10px;
	background-color: $bleuVert;
	color: #fff;
	z-index: 1041;
	@media (min-width: $screen-sm-min){
		height: 100px;
		padding-left: 20px;
		padding-right: 20px;
	}
	&__logo{
		width: 80px;
		height: 25px;
		@media (min-width: $screen-sm-min){	
			width: 160px;
			height: 50px;
		}
	}
	&__search{
		display: flex;
		align-items: center;
		padding-left: 20px;
		padding-right: 20px;
		color: #fff;
		flex-grow: 1;
		@media (min-width: $screen-sm-min){	
			padding-left: 40px;
			padding-right: 40px;
		}
		input{
			flex-grow: 1;
			padding-left: 10px;
			border-bottom: 1px solid white;
			background-color: transparent;
			color: inherit;
			font-family: inherit;
			font-size: inherit;
			outline: none;
			@media (min-width: $screen-sm-min){	
				padding-left: 5px;
			}
		}
		input::-webkit-input-placeholder{
			color: white;
			font-family: inherit;
			font-size: inherit;
			opacity: 1;
		}
		input:-moz-placeholder{
			color: white;
			font-family: inherit;
			font-size: inherit;
			opacity: 1;
		}
		input::-moz-placeholder{
			color: white;
			font-family: inherit;
			font-size: inherit;
			opacity: 1;
		}
		input:-ms-input-placeholder{
			color: white;
			font-family: inherit;
			font-size: inherit;
			opacity: 1;
		}
	}
	&__account{
		display: flex;
		align-items: baseline;
		position: relative;
		padding-top: 10px;
		padding-bottom: 10px;
		cursor: pointer;
		p{
			margin-bottom: 0;
		}
		strong{
			color: $bleu;
			font-size: 14px;
		}
		i{
			margin-left: 10px;
		}
	}
	&__account-menu{
		position: absolute;
		right: 0;
		top: 100%;
		padding: 20px;
		background-color: #fff;
		color: $colorMenu;
		font-weight: 600;
		text-transform: uppercase;
		box-shadow: 0 0 5px #999;
		transform: scale(0);
		transform-origin: 100% 0;
		transition: transform 0.3s ease-out;
		a{
			transition: color 0.3s ease-out;
			&:hover{
				color: $colorText;
			}
		}
	}
	&__account:hover &__account-menu{
		transform: scale(1);
	}
}