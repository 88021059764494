.textBlink {
    width: 200px;
    height: 50px;
    padding: 15px;
    text-align: center;
    line-height: 50px;
}

.blinkAnim {
    color: black;
    animation: blink 1.8s linear infinite;
}

@keyframes blink {
    0% {
        opacity: 0;
    }

    50% {
        opacity: .5;
    }

    100% {
        opacity: 1;
    }
}