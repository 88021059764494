tags-input[readonly] {
  .tags {
    background-color: #fff;
    cursor: default;

    .tag-item {
      opacity: 1;
      background: #a1dbff;
    }

    .input {
      display: none;
    }
  }
}