.background-letter{
	display: block;
	position: fixed;
	bottom: 0;
	right: 0;
	width: 197.5px;
	height: 138.5px;
	z-index: -1;
	@media (min-width: $screen-sm-min){
		width: 295px;
		height: 277px;
	}
}